.blue-header {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    /* identical to box height, or 120% */
    color: #003BB2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 20px 0px;
    gap: 10px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    word-wrap: break-word;
    width: 100%;
}

.blue-header-home {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    /* identical to box height, or 120% */
    color: #003BB2;
}
.bl-sector{
    background: #EBF0F5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    width: 100%;
}

.bl-sector-reg{
    background: rgba(2, 26, 51, 0.05);
}

.home-search {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    /* or 125% */
    color: #FFFFFF;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    width: 100%;
    /*min-width: 375px; */
}

.dotted-line{
    border-bottom:1px dashed #374B63;
    padding: 5px;
}

.timeline-1 {
    position: absolute;
    left: 19px;
    top: 17px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 18px;
    /* or 120% */
    color: #FFFFFF;
}

.timeline-year {
    position: absolute;
    width: 131px;
    height: 31px;
    left: 16px;
    top: 70px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 2em;
    line-height: 18px;
    /* or 33% */
    color: #FFFFFF;
}

.timeline-2 {
    position: absolute;
    left: 19px;
    top: 105px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 430;
    font-size: 1em;
    line-height: 15px;
    /* identical to box height, or 117% */
    color: #FFFFFF;
}

.indices-filter-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 8px 14px 10px;
    gap: 10px;
    width: 1343px;
    height: 73px;
    background: #EBF0F5;
    border-radius: 5px;
    align-self: stretch;
}

.indices-filter-panel-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 5px;
}

.indices-search{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 4px 5px;
    position: absolute;
    border: 1px solid #7B8891;
    border-radius: 2px;
}

.regulatory-text {
    height: 22px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */
    color: #0A5BCC;
}

.regulatory-boxes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 24px 0px;
    gap: 10px;
}

.text-dbiq-blue{
    width: 52px;
    height: 12px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 12px;
    /* identical to box height, or 57% */
    text-align: center;
    color: #00ACFF;
}

.brand-text{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    text-align: center;
    color: #141414;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 9px 12px;
    width: 43px;
    height: 30px;
}

.brand-text-border{
    border-right: 1px solid #ABB4BA;
    border-left: 1px solid #ABB4BA;
    width: 93px;
}

.divider{
    width: 517px;
    height: 1px;

    /* Stroke/Basic/Strong */

    background: #74828B;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.home-welcome-text {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    /* or 123% */
    color: #141414;
}

.home-notices-text{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* or 117% */
    color: #141414;
    display:inline;

}

.home-notices-text-date{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    /* or 117% */
    color: #141414;
    display:inline;
}
.home-publications-text{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* or 117% */
    color: #141414;
}
.modal-content {
    margin: 0;
    alignItems: undefined;
    justifyContent: undefined;
}

.timeline-text {
    width: 100%;
    height: 32px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    /* or 123% */
    color: #141414;
    flex: none;
    order: 0;
    flex-grow: 1;
}

.contact-img1{
    width: 720px;
    height: 542px;
    display: inline-block;
}

.contact-img2{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 140px 48px 24px;
    gap: 18px;

    width: 560px;
    height: 542px;

    /* Fill/Generic/Shade/Subtle */

    background: rgba(2, 26, 51, 0.05);
    flex: none;
    order: 1;
    flex-grow: 1;
}

.contact-text1{
    width: 182px;
    height: 36px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    /* identical to box height, or 120% */
    color: #000000;
}

.contact-text2{
    width: 289px;
    height: 26px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 26px;
    /* identical to box height, or 124% */
    color: #141414;
}

.contact-text3{
    width: 78px;
    height: 26px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 26px;
    /* identical to box height, or 124% */
    color: #141414;
}
.contact-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 122px;
    gap: 21px;
    position: absolute;
    width: 1017px;
    height: 303px;

    top: 631px;
    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 1;
}
.contact-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0px 0px;
    gap: 24px;
    width: 325px;
    height: 302.15px;
    background: #FFFFFF;
}

.contact-card-blue{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 325px;
    height: 106px;
    background: #1C2839;
    flex: none;
    order: 3;
    flex-grow: 0;
    font-family: 'Arial';
    font-style: normal;
    color: #FFFFFF;
    justify-content: center;
    padding: 1px 0px;
    gap: 4px;

}

.contact-content{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    width: 1280px;
    height: 750px;
    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 1;
}

.relevant-indices{
    box-sizing: border-box;
    display: inline-block;
    flex-direction: column;
    align-items: flex-start;
    padding: 9px 10px 31px 14px;
    gap: 6px;
    overflow-y: auto;
    width: 322px;
    height: 580px;
    border: 1px solid #74828B;
    border-radius: 5px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.contacts-icons{
    width: 35px;
    height: 35px;
}

.publ-relevant-indices-header{
    width: 95px;
    height: 22px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #141414;
}
.publ-relevant-indices-divider{
    width: 298px;
    height: 1px;
    background: #74828B;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

}
.publ-relevant-indices-text{
    width: 47px;
    height: 16px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    color: #0A5BCC;
}

.usual-text {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 117% */
 color: #141414;
}
.ag-icon.ag-icon-menu{
    min-width: 40px;
    min-height: 40px;
}
.ag-header-icon.ag-header-cell-menu-button{
    opacity: 1 !important;
}

.blue-index-header{
    padding: 10px 15px 10px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */
    color: #003BB2;
}

.indices-filters-divider{
    box-sizing: border-box;
    width: 1px;
    height: 40px;
    background: rgba(2, 26, 51, 0.2);
}

.footer-bar{
    height: 20px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height, or 86% */
    text-align: center;
    color: #141414;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 9px 10px;
}
.footer-divider{
    border-right: 1px solid #ABB4BA;
    border-radius: 0px;
}
.index-page{
    /*column-count: 2;*/
    /*display: flex;*/
    /*flex-flow:row wrap;*/
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
}

.index-item{
    width: 50%;

    page-break-inside: avoid; /* Theoretically FF 20+ */
    break-inside: avoid-column;
}

.reset-button {
    right: -10px;
    position: relative;
    top: -15px;
}

.modal-content {
    width: 624px;
}

.modal-backdrop {
    width: 100%;
    height: 100%;
}

.ag-cell-auto-height {
    height: auto;
}

.my-navbar {
    width: 100%;
    flex-float: row wrap !important;
}

@media screen and (max-width:414px) {
    .my-graph {
        min-width: 500px !important;
        min-height: 500px !important;
        .recharts-wrapper {
            width: 100% !important
        }
    }
}